import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TodoListSkeleton from "./Skeletons/TodoList"

const ScheduleRows = ({ tasks }) => {

    if (!tasks) {
        return <TodoListSkeleton />
    }

    return (<Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow >
                        {["Task", "Duration", "Time"].map((column) => (
                            <TableCell sx={{ backgroundColor: "#87c8c2", color: "#fff" }} align="left" >{column}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks.filter(f => !f.completed)
                        .map((row, i) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell align={"left"}>{row.taskName}</TableCell>
                                    <TableCell align={"left"}>{row.duration}</TableCell>
                                    <TableCell align={"left"}>{row.time}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>)

}

export default ScheduleRows;
