
import FocuslyIcon from "../assets/focusly.png"

import styled from 'styled-components';
const Header = styled.div`
  display:grid;
  align-items: center;
  justify-items: center;
`

const HeaderIcon = styled.div`
  height:150px;
  margin: 15px auto;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`

const HeaderComponent = () => (
    <Header>
        <HeaderIcon>
            <img src={FocuslyIcon} alt="focusly icon" />
        </HeaderIcon>
    </Header>
);

export default HeaderComponent;