import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TodoListSkeleton = () => {
    return (
        <>
            {[...Array(6)].map((_, index) => (
                <Skeleton key={index} variant="text" sx={{ fontSize: '50px' }} />
            ))}
        </>
    )
}

export default TodoListSkeleton;