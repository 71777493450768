import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import InfoBar from "../components/AppBar"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from '../settings/theme';
import { Container, Paper } from '@mui/material';
import { Outlet, useNavigate, useOutletContext, useLocation } from "react-router-dom";
import TaskViewMenu from '../components/TaskViewMenu';

const Layout = ({ children }) => {
    const { pathname } = useLocation();
    const [user, setUser] = useState();
    const [profile, setProfile] = useState();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            localStorage.setItem('user', JSON.stringify(codeResponse));
        },
        onError: (error) => console.log('Login Failed:', error),
        prompt: 'consent',
        accessType: 'offline', // Add this to get a refresh token
    });

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    useEffect(
        () => {
            if (user && user.access_token) {
                console.log("Getting User Profile From Google")
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        }, [user]);

    useEffect(() => {
        // Check if the user has a refresh token and it's time to refresh
        if (user && user.refresh_token && user.expires_in <= 60) {
            axios
                .post('https://www.googleapis.com/oauth2/v4/token', null, {
                    params: {
                        // client_id: clientId,
                        // client_secret: clientSecret,
                        refresh_token: user.refresh_token,
                        grant_type: 'refresh_token',
                    },
                })
                .then((res) => {
                    const updatedUser = {
                        ...user,
                        access_token: res.data.access_token,
                        expires_in: res.data.expires_in,
                    };
                    setUser(updatedUser);
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
        localStorage.removeItem('user');
    };
    const theme = createTheme(themeOptions);
    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ minHeight: '650px' }} maxWidth="md" disableGutters>
                <InfoBar profile={profile} logOut={logOut} login={login} />
                <Paper
                    sx={{ minHeight: '650px', padding: "20px 20px" }}
                >
                    <Outlet context={{ login, logOut, profile }} />
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

export default Layout;
