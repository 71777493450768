import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../components/Header"
import LoginPrompt from '../components/LoginPrompt';
import { useOutletContext } from "react-router-dom";
import WelcomeMessage from '../components/WelcomeMessage';
import ScheduleRows from '../components/ScheduleRows';
import _ from 'lodash';
import { Button, CircularProgress } from '@mui/material';
import TaskViewMenu from '../components/TaskViewMenu';

function sortTasks(tasks) {
    return _.chain(tasks)
        .sortBy([(task) => !task.completed, 'timestamp'])
        .reverse()
        .value();
}


function App() {
    const { login, profile } = useOutletContext();
    const [taskList, setTaskList] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleRecalculate = (filteredTasks) => {
        setIsLoading(true);

        axios
            .post('https://australia-southeast1-focusly-386111.cloudfunctions.net/focusly-server/api/recalculateTasks', { tasks: filteredTasks })
            .then((res) => {
                setTaskList(res.data);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (profile && profile.email && !profile.user) {
            axios.get(`https://australia-southeast1-focusly-386111.cloudfunctions.net/focusly-server/api/getTasks?email=${profile.email}`)
                .then((res) => {
                    const { tasks } = res.data;
                    console.log(sortTasks(tasks))
                    setTaskList(sortTasks(tasks));
                }).catch(err => console.log(err))
        }
        if (!profile) {
            setTaskList(null);
        }
    }, [profile]);

    return (
        <div>
            <Header />
            {profile && profile.email ? (
                <>
                    <WelcomeMessage profile={profile} />
                    <TaskViewMenu pathname={"/schedule"} />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        onClick={() => handleRecalculate(taskList.filter((f) => !f.completed))}
                        endIcon={isLoading ? <CircularProgress size={20} /> : null}
                    >
                        {isLoading ? 'Loading...' : 'Recalculate'}
                    </Button>
                    <ScheduleRows tasks={taskList} email={profile.email} />
                </>
            ) :
                <LoginPrompt login={login} />}
        </div>
    );
}

export default App;
