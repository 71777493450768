import axios from 'axios';

const baseURL = "https://australia-southeast1-focusly-386111.cloudfunctions.net";
// const baseURL = "http://localhost:3002"

const handleTaskClick = async ({ event, taskId, index, todoList, setTodoList }) => {
    const completed = !todoList[index].completed;
    const newToDoList = [...todoList];
    newToDoList[index].completed = completed;
    await axios.post(`${baseURL}/focusly-server/api/updateRow`, { taskId, completed })
        .then(res => console.log(res))
        .catch(err => console.log(err))
    setTodoList(newToDoList);
}

const deleteTask = async ({ event, taskId, index, todoList, setTodoList }) => {
    const removeItemFromArray = (array, index) => [...array.slice(0, index), ...array.slice(index + 1)];
    const newToDoList = removeItemFromArray(todoList, index);
    setTodoList(newToDoList);
    await axios.post(`${baseURL}/focusly-server/api/deleteTask`, { taskId })
        .then(res => console.log(res))
        .catch(err => console.log(err))
    console.log("Task Deleted");
}

const addTask = async ({ event, todoList, setTodoList, newTask, setNewTask, email }) => {
    const task = {
        taskName: newTask, completed: false, email, timestamp: new Date(),
        taskType: "task", duration: null, time: null, website: null
    };
    const newToDoList = [task, ...todoList];
    setTodoList(newToDoList);
    setNewTask("");
    const createdTask = await axios.post(`${baseURL}/focusly-server/api/addTask`, { task })
        .catch(err => console.log(err))
    console.log("Task Added");
    console.log(task);
    return createdTask;
}

export { handleTaskClick, deleteTask, addTask }