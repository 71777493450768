import React from 'react';
import styled from 'styled-components';

const WelcomeMessage = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 15px auto;
  color: #42a198;
`;

const WelcomeMessageComponent = ({ profile }) => {
    return <WelcomeMessage>Welcome back, {profile.given_name || profile.name}!</WelcomeMessage>;
}

export default WelcomeMessageComponent;