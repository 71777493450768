import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment
} from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { addTask } from "../services/taskManagement"
import TodoList from './TodoListRows';

const TodoListConsole = ({ tasks, email }) => {
  const [todoList, setTodoList] = useState(tasks);
  const [newTask, setNewTask] = useState("");

  useEffect(() => {
    if (tasks) setTodoList(tasks)
  }, [tasks])

  const addToast = (message) => {
    console.log(message)
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 3000 });
  };

  const handleAddTask = () => {
    if (newTask.trim() !== "") {
      addTask({ todoList, newTask, setTodoList, email, setNewTask }).then(res => {
        addToast(res.data.tip);
      })
    }
  };

  return (
    <div>
      <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: "left", vertical: isMobile ? "top" : "bottom" }} />
      <TextField
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => handleAddTask()}
              position="end">
              <AddCircleIcon />
            </InputAdornment>
          ),
        }}
        // helperText="Walk the dog"
        variant="filled"
        label="Add a new task"
        value={newTask}
        onChange={(e) => setNewTask(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAddTask();
          }
        }}
      />
      <TodoList todoList={todoList} setTodoList={setTodoList} />
    </div>
  );
};

export default TodoListConsole;
