export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#42a198',
        },
        secondary: {
            main: '#a1424b',
        },
    },
};