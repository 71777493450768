import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../components/Header"
import LoginPrompt from '../components/LoginPrompt';
import { useOutletContext } from "react-router-dom";
import WelcomeMessage from '../components/WelcomeMessage';
import TaskViewMenu from '../components/TaskViewMenu';
import TodoListConsole from '../components/TodoConsole';
import _ from 'lodash';

function sortTasks(tasks) {
    return _.chain(tasks)
        .sortBy([(task) => !task.completed, 'timestamp'])
        .reverse()
        .value();
}


function App() {
    const { login, profile } = useOutletContext();
    const [taskList, setTaskList] = useState();

    useEffect(() => {
        if (profile && profile.email && !profile.user) {
            axios.get(`https://australia-southeast1-focusly-386111.cloudfunctions.net/focusly-server/api/getTasks?email=${profile.email}`)
                .then((res) => {
                    const { tasks } = res.data;
                    console.log(tasks)
                    console.log(sortTasks(tasks))
                    setTaskList(sortTasks(tasks));
                }).catch(err => console.log(err))
        }
        if (!profile) {
            setTaskList(null);
        }
    }, [profile]);

    return (
        <div>
            <Header />
            {profile && profile.email ? (
                <>
                    <WelcomeMessage profile={profile} />
                    <TaskViewMenu pathname={"/"} />
                    <TodoListConsole tasks={taskList} email={profile.email} />
                </>
            ) :
                <LoginPrompt login={login} />}
        </div>
    );
}

export default App;
