import React from 'react';
import { Chip, Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const TaskViewMenu = ({ pathname }) => {
    const setChipVariant = (path) => {
        return { variant: pathname === path ? 'filled' : 'outlined' };
    };
    return (
        <Box display="flex" justifyContent="center" width="100%">
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Chip color="primary" label="Task View" component={Link} to="/" clickable {...setChipVariant('/')} />
                <Chip color="primary" label="Schedule view" component={Link} to="/schedule" clickable {...setChipVariant('/schedule')} />
            </Stack>
        </Box>
    );
};

export default TaskViewMenu;
