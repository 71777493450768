import React from 'react';
import {
    List, ListItemIcon, ListItemText, IconButton,
    Checkbox, ListItem, ListItemButton,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { handleTaskClick, deleteTask } from "../services/taskManagement";

import TodoListSkeleton from './Skeletons/TodoList';

const TodoListRows = ({ todoList, setTodoList }) => {
    
    if (!todoList) {
        return <TodoListSkeleton />
    }

    return (
        <List sx={{ width: '100%', bgcolor: '#f5f5f5' }}>
            {todoList.map((task, index) => (
                <ListItem
                    key={index}
                    secondaryAction={ // Add the trashcan icon button
                        <IconButton edge="end" aria-label="delete" onClick={(event) =>
                            deleteTask({ event, taskId: task.id, index, todoList, setTodoList })}>
                            <DeleteIcon />
                        </IconButton>
                    }
                    disablePadding
                >
                    <ListItemButton
                        role={undefined}
                        sx={{
                            bgcolor: '#fff',
                            backgroundClip: 'content-box',
                            padding: "3px 10px 3px 10px!important"
                        }}
                        onClick={(event) =>
                            handleTaskClick({ event, taskId: task.id, index, todoList, setTodoList })}
                        dense
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={task.completed}
                                key={index}
                                disabled={task.isDisabled}
                                sx={{
                                    color: "#42a198",
                                    '&.Mui-checked': {
                                        color: "#42a198",
                                    },
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id={`checkbox-list-label-${task.id}`}
                            primary={task.taskName}
                            sx={() => task.completed && {
                                textDecoration: 'line-through',
                                color: 'gray',
                            }
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>)
}

export default TodoListRows;