import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const ProfileField = ({ label, value, onChange, disabled, error, errorMessage }) => {
  return (
    <Grid item xs={6}>
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
        disabled={disabled}
        error={error} // Added error prop
        helperText={error ? errorMessage : null} // Added helperText prop for error message
      />
    </Grid>
  );
};

export default ProfileField;
