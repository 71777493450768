import { Box, Button, AlertTitle, Alert } from '@mui/material';

const LoginPrompt = ({ login }) => (
    <>  <Alert severity="warning">We're aware of an issue where users don't stay logged in for longer than an hour and currently working on it. Sit tight!</Alert>
        <Alert severity="info">
            <AlertTitle>Please log in</AlertTitle>
            You're logged out. Please login to view your tasks.
            <Box
                sx={{
                    margin: '10px auto',
                }}
            ><Button variant="outlined" onClick={login}>Login</Button></Box>
        </Alert>

    </>
);

export default LoginPrompt;