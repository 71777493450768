import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ProfileField from "../components/ProfileField"
import { Divider, Chip } from '@mui/material';
import { useOutletContext } from "react-router-dom";
import { Paper, Skeleton, Box } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import LoginPrompt from '../components/LoginPrompt';
import axios from 'axios';

const baseURL = "https://australia-southeast1-focusly-386111.cloudfunctions.net";
// const baseURL = "http://localhost:3002";

const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match Australian mobile numbers
    const regex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
    return regex.test(phoneNumber);
};

const EditProfile = () => {
    const { profile, login } = useOutletContext();
    const [userProfile, setProfile] = useState();

    useEffect(() => {
        if (profile && profile.email) {
            axios
                .get(`${baseURL}/focusly-server/api/getProfile?email=${profile.email}`)
                .then((res) => {
                    console.log(res.data);
                    setProfile(res.data);
                })
                .catch(err => console.log(err));
        }
    }, [profile]);

    const handleProfileChange = (event, field) => {
        const newProfile = { ...userProfile, [field]: event.target.value };
        setProfile(newProfile);
    };

    const handleCallTimeChange = (event, day) => {
        const newCallTimes = { ...userProfile.callTime, [day.toLowerCase()]: event.target.value };
        const newProfile = { ...userProfile, callTime: newCallTimes };
        setProfile(newProfile);
    };

    function formatAustralianPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the phone number
        const cleanedNumber = phoneNumber.replace(/\D/g, '');

        // Check if the number starts with a country code
        if (cleanedNumber.startsWith('61')) {
            // If the number starts with '61', it's already in the desired format
            return cleanedNumber;
        }

        // Check if the number starts with a leading '0'
        if (cleanedNumber.startsWith('0')) {
            // Remove the leading '0' and prepend '61'
            return '61' + cleanedNumber.slice(1);
        }

        // If no leading '0' or '61' is found, assume it's in the desired format
        return cleanedNumber;
    }


    const handleSubmit = event => {
        event.preventDefault();
        if (!isValidPhoneNumber(userProfile.mobile)) {
            enqueueSnackbar("Please enter a valid Australian mobile phone number.", { variant: "error", autoHideDuration: 3000 });
            return;
        }
        const newProfile = { ...userProfile, mobile: formatAustralianPhoneNumber(userProfile.mobile) }
        axios.post(`${baseURL}/focusly-server/api/updateProfile`, newProfile)
            .then(res => {
                console.log(res);
                enqueueSnackbar("Profile updated!", { variant: "success", autoHideDuration: 3000 });
            })
            .catch(err => console.log(err))
    };

    if (!profile || !profile.email) {
        return <LoginPrompt login={login} />
    }
    if (!userProfile) {
        return (
            <Paper sx={{ padding: "20px 20px" }}   >
                <Box >
                    <Skeleton animation="wave" variant='rectangular' height={500} />
                </Box>
            </Paper>
        );
    }

    function createTimeList() {
        const times = [];
        const intervals = 15;

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += intervals) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                const value = `${hour.toString()}${formattedMinute}`;
                const time = `${formattedHour}:${formattedMinute}`;
                if (
                    (hour >= 7 && hour < 12) ||
                    (hour >= 17 && hour < 19)
                ) {
                    times.push({ value, label: time });
                }
            }
        }
        return times.map(time => (
            <option key={time.value} value={time.value} label={time.label}>
                {/* {time} */}
            </option>))
    }
    return (
        <Paper
            sx={{ padding: "20px 20px" }}

        >
            <SnackbarProvider maxSnack={5} />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <ProfileField label="Email" value={userProfile.email} onChange={event => handleProfileChange(event, 'email')} disabled />
                    <Grid item xs={6}>
                        <TextField
                            label="Follow-up Email Time"
                            value={userProfile.followupEmail}
                            onChange={event => handleProfileChange(event, 'followupEmail')}
                            fullWidth
                            select
                            disabled
                            SelectProps={{
                                native: true
                            }}
                        >
                            <option value={userProfile.followupEmail} label={"20:00"}></option>
                        </TextField>
                    </Grid>
                    <ProfileField label="First Name" value={userProfile.firstName} onChange={event => handleProfileChange(event, 'firstName')} />
                    <ProfileField
                        label="Mobile Number"
                        //   disabled
                        value={userProfile.mobile}
                        onChange={event => handleProfileChange(event, 'mobile')}
                        error={!isValidPhoneNumber(userProfile.mobile)} // Check for validation error
                        errorMessage="Please enter a valid Australian mobile number" // Error message text
                    />
                    <Grid item xs={12}>
                        <Divider variant='middle' sx={{ marginBottom: "10px" }}>
                            <Chip label="Call Times" />
                        </Divider>
                        <Grid container spacing={2}>
                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                                <Grid item xs={4} key={day}>
                                    <TextField
                                        label={`${day}`}
                                        value={userProfile.callTime[day.toLowerCase()]}
                                        onChange={event => handleCallTimeChange(event, day)}
                                        fullWidth
                                        select
                                        SelectProps={{
                                            native: true
                                        }}
                                    >
                                        <option value=""></option>
                                        {createTimeList()}
                                    </TextField>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default EditProfile;
